import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1440,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: "#54B6F9",
        },
        secondary: {
            main: "#1A1A1A",
            light: "#ACAFBF",
        },
        text: {
            primary: "#000",
        },
    },
});

theme.overrides = {
    MuiCssBaseline: {
        "@global": {
            body: {
                backgroundColor: "white",
            },
            em: {
                color: theme.palette.primary.main,
                fontWeight: 500,
            },
            p: {
                marginTop: 0,
            },
        },
    },
    MuiTypography: {
        body1: {
            fontFamily: '"Helvetica", "Arial", sans-serif',
        },
        h1: {
            fontFamily: '"Helvetica", "Arial", sans-serif',
            [theme.breakpoints.up("xs")]: {
                fontSize: "1.9rem",
                lineHeight: "1.9rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "2.8rem",
                lineHeight: "2.8rem",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "3.7rem",
                lineHeight: "3.7rem",
            },
            fontWeight: "600",
            color: theme.palette.text.primary,
        },
        h2: {
            fontFamily: '"Helvetica", "Arial", sans-serif',
            [theme.breakpoints.up("xs")]: {
                fontSize: "1.8rem",
                lineHeight: "1.8rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "2.5rem",
                lineHeight: "2.5rem",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "3.1rem",
                lineHeight: "3.1rem",
            },
            fontWeight: "600",
        },
        h3: {
            fontFamily: '"Helvetica", "Arial", sans-serif',
            [theme.breakpoints.up("xs")]: {
                fontSize: "1.1rem",
                lineHeight: "1.1rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "1.3rem",
                lineHeight: "1.3rem",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.75rem",
                lineHeight: "1.75rem",
            },
            fontWeight: "600",
        },
        h5: {
            [theme.breakpoints.up("xs")]: {
                fontSize: "1rem",
                lineHeight: "1.5rem",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "1.2rem",
                lineHeight: "1.5rem",
            },
            fontWeight: "600",
        },
    },
    MuiMenuItem: {
        root: {
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            "&$selected": {
                color: "#54B6F9",
                backgroundColor: "white",
            },
        },
    },
    MuiMenu: {
        paper: {
            borderRadius: 0,
        },
        list: {
            paddingTop: "1.5rem",
            paddingBottom: "1.5rem",
        },
    },
    MuiSelect: {
        select: {
            "&.MuiSelect-select": {
                paddingRight: "0.5rem",
            },
            marginLeft: "1.7rem",
            "&:focus": {
                backgroundColor: "white",
            },
        },
    },
};

export default theme;
