export const businessRoles = [
    { id: 1, key: "director", name: "Исполнительный директор" },
    { id: 2, key: "chairman", name: "Председатель" },
    { id: 3, key: "viceChairman", name: "Заместитель председателя" },
    { id: 4, key: "generalSecretary", name: "Генеральный секретарь" },
    { id: 5, key: "trainer", name: "Тренер" },
    { id: 6, key: "sportsman", name: "Спортсмен" },
] as const;

export type BusinessRoles = typeof businessRoles;
export type BusinessRole = BusinessRoles[number];
export type BusinessRoleId = BusinessRole["id"];
export type BusinessRoleKey = BusinessRole["key"];
export type BusinessRoleById<TId extends BusinessRoleId> = Extract<BusinessRole, { id: TId }>;
export type BusinessRoleByKey<TKey extends BusinessRoleKey> = Extract<BusinessRole, { key: TKey }>;
export type BusinessRoleIds = { [k in BusinessRoleKey]: BusinessRoleByKey<k>["id"] };

export const isBusinessRoleKey = (key: any): key is BusinessRoleKey => businessRoles.some((br) => br.key === key);
export const isBusinessRoleId = (id: any): id is BusinessRoleId => businessRoles.some((br) => br.id === id);

export const getBusinessRoleByKey = <TKey extends BusinessRoleKey>(key: TKey) =>
    businessRoles.find((t) => t.key === key) as BusinessRoleByKey<TKey>;

export const getBusinessRoleById = <TId extends BusinessRoleId>(id: TId) =>
    businessRoles.find((r) => r.id === id) as BusinessRoleById<TId>;

export const businessRoleIds = businessRoles.reduce((r, br) => ({ ...r, [br.key]: br.id }), {}) as BusinessRoleIds;
