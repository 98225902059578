import { sharedSelectors } from "@Admin/helpers/selectors";
import { signActions } from "@Admin/redux/actions/SharedActions";
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./LoginContainer.module.scss";
import SportProLogoFull from "@Admin/assets/sportProLogo-full.svg";
import RestorePasswordForm from "./RestorePasswordForm/RestorePasswordForm";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import LoginForm from "./LoginForm/LoginForm";

interface LoginContainerProps {
    authMessage?: string;
    authError?: string | null;
    loginAction: (data: any) => (dispatch: any) => void;
    restoreAction: (data: any) => (dispatch: any) => void;
    confirmAction: (loginData: any, passwordData: any) => (dispatch: any) => void;
    resetAuthError: () => (dispatch: any) => void;
    isLoading: boolean;
}

const LoginContainer = (props: LoginContainerProps) => {
    const { loginAction, restoreAction, confirmAction, ...restProps } = props;

    const params = useParams();

    const action = params.action ?? "";
    const renderActionComponent = () => {
        switch (action) {
            case "confirm":
                return <ChangePasswordForm confirmAction={confirmAction} {...restProps} />;
            case "restore":
                return <RestorePasswordForm restoreAction={restoreAction} {...restProps} />;
            default:
                return <LoginForm loginAction={loginAction} {...restProps} />;
        }
    };
    return (
        <div className={styles.wrapper}>
            <div className={styles.logoWrapper}>
                <div className={styles.logo}>
                    <SportProLogoFull className={styles.icon} />
                </div>
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.form} tabIndex={-1}>
                    {renderActionComponent()}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authError: sharedSelectors.authError(state),
    authMessage: sharedSelectors.authMessage(state),
    isLoading: sharedSelectors.isLoading(state),
});

const mapDispatchToProps = {
    loginAction: signActions.loginAction,
    restoreAction: signActions.restoreAction,
    confirmAction: signActions.confirmAction,
    resetAuthError: signActions.resetAuthError,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
